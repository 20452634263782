/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";
html {
    font-size: 14px;
}

body {
    margin: 0;
    font-family: var(--font-family);
    font-weight: normal;
    background: var(--surface-ground);
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

app-top-menu-bar{
    p-toolbar{
        .p-toolbar{
            border: unset !important;
        }
    }
    
}
.card {
    background: var(--surface-card);
    border-radius: 10px;
    margin-bottom: 1rem;
}
.login-component{
    display: flex;
    justify-content: center;
    .pass-full{
        width: 100%;
        display: block;
        .p-inputwrapper{
            width: 100%;
            input{
                width: 100%;
            }
        }
    }
}
.question-card{
    p-panel {
        display: block;
        .p-panel{
            height: 100%;
            display: flex;
                flex-direction: column;
            .p-toggleable-content{
                flex: 1;
                display: flex;
                flex-direction: column;
                .p-panel-content{
                    flex:1
                }
            }
        }
    }  
}
.chip-cls{
    min-width:62px;
    display: block;
}
